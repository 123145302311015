import React from "react";
import {
    Table,
    ActionIcon,
    Text,
    Pagination,
    Badge,
    Select,
} from "@mantine/core";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { useNavigate } from "react-router-dom";
import { Eye, SortAscending, Download } from "tabler-icons-react";
import { formatNumberWithCommas } from "../../utilities/utilities";

const DashboardTableComponent = ({
    tableData,
    tableCount,
    formState,
    setFormState,
    from
}) => {
    const navigate = useNavigate();

    const handleExcelDownload = async () => {
        const mappedData = tableData.map(item => ({
            "Ref no": `recovery-${item.id}`,
            "Notice Count": parseInt(item.noticeCount, 10),
            "Debtor Name": item.debtorName,
            "Business Unit": item.businessUnit,
            "Total Amount": formatNumberWithCommas(item.totalAmount),
            "Invoice Amount":  formatNumberWithCommas(item.invoiceAmount),
            "Status": item.status || "Incomplete",
            "Ageing": `${item.ageing} days`
        }));
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        worksheet.columns = [
            { header: 'Ref no.', key: 'Ref no', width: 15 },
            { header: 'Notice Count', key: 'Notice Count', width: 15 },
            { header: 'Debtor Name', key: 'Debtor Name', width: 20 },
            { header: 'Business Unit', key: 'Business Unit', width: 20 },
            { header: 'Total Amount', key: 'Total Amount', width: 15 },
            { header: 'Invoice Amount', key: 'Invoice Amount', width: 15 },
            { header: 'Status', key: 'Status', width: 10 },
            { header: 'Ageing', key: 'Ageing', width: 10 }
        ];

        const headerRow = worksheet.getRow(1);
        headerRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (colNumber <= 8) {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFADD8E6' }
                };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            }
        });
        mappedData.forEach((data) => {
            const row = worksheet.addRow(data);
            row.eachCell((cell) => {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `recovery ${from}.xlsx`);
    };

    return (
        <>
            <div className="flex items-center justify-end mb-5">
                Sort:
                <Select
                    placeholder="Sort by"
                    className="mx-2"
                    value={formState.filter.orderByColumn}
                    onChange={(value) => {
                        setFormState({
                            ...formState,
                            filter: {
                                ...formState.filter,
                                orderByColumn: value,
                            },
                            apiCall: true,
                        });
                    }}
                    data={[
                        { label: "Recent", value: "" },
                        { label: "Reference no.", value: "r.id" },
                        { label: "Notice Count", value: "noticeCount" },
                        { label: "Debtor Name", value: "debtorName" },
                        { label: "Business Unit", value: "businessUnit" },
                        { label: "Invoice Amount", value: "invoiceAmount" },
                        { label: "Total Amount", value: "totalAmount" },
                        { label: "Status", value: "status" },
                        { label: "Ageing", value: "ageing" },
                    ]}
                />
                <ActionIcon
                    onClick={() => {
                        setFormState((prevState) => ({
                            ...prevState,
                            filter: {
                                ...prevState.filter,
                                sortType: prevState.filter.sortType === "ASC" ? "DESC" : "ASC",
                            },
                            apiCall: true,
                        }));
                    }}
                    color="blue"
                    className={formState.filter.sortType === "ASC" ? "" : "rotate-180"}
                >
                    <SortAscending />
                </ActionIcon>
                <ActionIcon
                    className="ml-5"
                    title="Excel Download"
                    onClick={handleExcelDownload}
                >
                    <Download color="green" />
                </ActionIcon>
            </div>
            <Table striped highlightOnHover={true}>
                <thead>
                    <tr>
                        <th>reference no.</th>
                        <th>notice count</th>
                        <th>debtor name</th>
                        <th>business unit</th>
                        <th>invoice amount</th>
                        <th>total amount</th>
                        <th>status</th>
                        <th>ageing</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.length === 0 ? (
                        <tr>
                            <td colSpan={9} className="text-center">
                                <Text size={22} className="m-8">
                                    no data found
                                </Text>
                            </td>
                        </tr>
                    ) : (
                        tableData.map((row) => {
                            let statusBadge;
                            if (row.status === "Pending") {
                                statusBadge = (
                                    <td>
                                        <Badge color="orange">{row.status}</Badge>
                                    </td>
                                );
                            } else if (row.status === "Fully Recovered") {
                                statusBadge = (
                                    <td>
                                        <Badge color="green">{row.status}</Badge>
                                    </td>
                                );
                            } else if (row.status === "Settlement") {
                                statusBadge = (
                                    <td>
                                        <Badge color="blue">{row.status}</Badge>
                                    </td>
                                );
                            } else if (row.status === "Case Creation") {
                                statusBadge = (
                                    <td>
                                        <Badge color="red">{row.status}</Badge>
                                    </td>
                                );
                            } else if (row.status === "Write off") {
                                statusBadge = (
                                    <td>
                                        <Badge color="blue">{row.status}</Badge>
                                    </td>
                                );
                            } else if (row.status === "Active") {
                                statusBadge = (
                                    <td>
                                        <Badge color="green">{row.status}</Badge>
                                    </td>
                                );
                            } else {
                                statusBadge = (
                                    <td>
                                        <Badge color="red">Incomplete</Badge>
                                    </td>
                                );
                            }
                            return (
                                <tr key={row.id}>
                                    <td>{`recovery-${row.id}`}</td>
                                    <td className="text-right">{row.noticeCount}</td>
                                    <td>{row.debtorName}</td>
                                    <td>{row.businessUnit}</td>
                                    <td className="text-right">
                                        {formatNumberWithCommas(row.invoiceAmount)}
                                    </td>
                                    <td className="text-right">
                                        {formatNumberWithCommas(row.totalAmount)}
                                    </td>
                                    {statusBadge}
                                    <td>{`${row.ageing} days`}</td>
                                    <td>
                                        <ActionIcon
                                            color="blue"
                                            title="View"
                                            onClick={() => navigate(`/app/recovery/d/${row.id}`)}
                                        >
                                            <Eye />
                                        </ActionIcon>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
            {tableCount > 0 ? (
                <div className="flex flex-row justify-center items-center my-4">
                    <Pagination
                        className="mx-2"
                        page={formState.pageNo}
                        onChange={(page) => {
                            setFormState((prevState) => ({
                                ...prevState,
                                pageNo: page,
                                apiCall: true,
                            }));
                        }}
                        total={Math.ceil(tableCount / formState.pageSize)}
                    />
                    <Text>select page size</Text>
                    <Select
                        className="mx-2 w-[75px]"
                        data={["10", "20", "50", "100"]}
                        value={formState.pageSize}
                        onChange={(value) => {
                            setFormState((prevState) => ({
                                ...prevState,
                                pageSize: value,
                                pageNo: 1,
                                apiCall: true,
                            }));
                        }}
                    />
                </div>
            ) : null}
        </>
    );
};

export default DashboardTableComponent;
